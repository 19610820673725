import React from "react";
import "./styleAudit.scss";
import moment from "moment";
import { DATE_FORMAT } from "../../constants/app-constants";

const AuditLog = (props) => {
  return (
    <div>
      {(props?.logData?.created_by || props?.logData?.created_at) && (
        <p className="fs-10 fs-i text-secondary p-0 m-0">
          Created{" "}
          {props?.logData?.created_by ? "by " + props?.logData?.created_by : ""}{" "}
          on{" "}
          {props?.logData?.created_at
            ? moment.utc(props?.logData?.created_at).format(DATE_FORMAT)
            : "-"}
        </p>
      )}
      {(props?.logData?.updated_by || props?.logData?.updated_at) && (
        <p className="fs-10 fs-i text-secondary p-0 m-0">
          Updated{" "}
          {props?.logData?.updated_by ? "by " + props?.logData?.updated_by : ""}{" "}
          on{" "}
          {props?.logData?.updated_at
            ? moment.utc(props?.logData?.updated_at).format(DATE_FORMAT)
            : "-"}
        </p>
      )}
    </div>
  );
};

export default AuditLog;
