import React, { useState } from 'react'
import ConfirmationPopup from '../../widgets/Popups/Confirmation-Popup';
import Inputcomp from '../../widgets/Form/input/Input';
import { ERROR_MESSAGES, REQUIRED_ERROR, SUCCESS_MESSAGES } from '../../constants/app-constants';
import AuditLog from '../../widgets/AuditLog/AuditLog';
import { toast } from 'react-toastify';
import { executionEngineService } from '../../_services/executionengine.service';
import { Link, navigate } from 'gatsby';

const ExecutionEngineForm = (props) => {
  const [confirmData, setConfirmData] = useState({
    title: "",
    message: "",
    closeLabel: "",
    okLabel: "",
    isShow: false,
    selectedId: "",
  });

  const [executionEngineForm, setExecutionEngineForm] = useState({
    execution_engine_key: "",
    name: "",
    service_name: "",
    service_short_name: "",
    task_definiton_worker: "",
    max_count: 0,
    variables: null
  });

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    isValid: false,
    execution_engine_key: "Execution Engine Key Required",
    name: "Name Required",
    service_name: "Service Name Required",
    service_short_name: "Service Short Name Required",
    task_definiton_worker: "Task Definition Worker Required",
  });

  const [state, setState] = useState({
    rows: [],
    buildArgs: [],
  });

  React.useEffect(() => {
    state.rows.length == 0 ? handleAddRow() : "";
  }, []);

  React.useEffect(() => {
    let executionEngine = props?.executionEngineDetail;
    setExecutionEngineForm({
      execution_engine_key: executionEngine?.execution_engine_key,
      name: executionEngine?.name,
      service_name: executionEngine?.service_name,
      service_short_name: executionEngine?.service_short_name,
      task_definiton_worker: executionEngine?.task_definiton_worker,
      max_count: executionEngine?.max_count ? executionEngine?.max_count : 0,
      variables: executionEngine?.variables ? executionEngine?.variables : null,
    });

    let rows = [];
    executionEngine?.variables &&
      Object.keys(executionEngine?.variables).map((key, index) => {
        let k = {
          key: key,
          value: executionEngine?.variables[key],
        };
        rows.push(k);
      });
    setState({ ...state, rows, buildArgs: executionEngine?.variables });
  }, [props?.executionEngineDetail]);

  function closeConfirm() {
    setConfirmData({
      ...confirmData,
      isShow: false,
    });
  }

  function onUpdateCancel() {
    closeConfirm();
    props?.handelEditWithoutChange(false);
  }

  function handleChangeForm(event) {
    Object.keys(event).forEach(function (key) {
      setExecutionEngineForm({ ...executionEngineForm, [key]: event[key] });
    });
  }

  const handleChangeFormWithouspace = (event) => {
    Object.keys(event).forEach(function (key) {
      let value = event[key].replace(/\s/g, "");
      setExecutionEngineForm({ ...executionEngineForm, [key]: value });
    });
  };

  function variableValidation(param) {
    if (param) {
      for (const [key, value] of Object.entries(param)) {
        if (key.trim() == "" || value == "") {
          return false;
        }
      }
    }
    return true;
  }

  function validateForm() {
    let variable_vali = variableValidation(executionEngineForm.variables);
    if (
      executionEngineForm.execution_engine_key &&
      executionEngineForm?.name &&
      variableValidation(executionEngineForm.variables)
    ) {
      setError({ ...error, isValid: false });
      return true;
    } else {
      setError({ ...error, isValid: true });
      return false;
    }
  }

  function onSubmit(event) {
    props?.isLoading(true);

    if (!validateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }

    let req = {
      execution_engine_key: executionEngineForm?.execution_engine_key,
      name: executionEngineForm?.name,
      service_name: executionEngineForm?.service_name,
      service_short_name: executionEngineForm?.service_short_name,
      task_definiton_worker: executionEngineForm?.task_definiton_worker,
      max_count: executionEngineForm?.max_count
        ? executionEngineForm?.max_count
        : 0,
      variables: executionEngineForm?.variables,
    };

    executionEngineService.createExecutionEngine(req).then((resp) => {
      if (resp.status == 200 && resp?.data) {
        props?.isLoading(false);
        toast.success(SUCCESS_MESSAGES.add);
        navigate(`/app/execution-engines/${resp?.data?.execution_engine_key}`);
      } else if (resp?.status == 408) {
        toast.error(ERROR_MESSAGES.timeExceedError);
        props?.isLoading(false);
      } else {
        if (resp?.status === 422) {
          toast.error(resp?.data?.detail[0].msg);
        } else {
          toast.error(
            resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          );
        }
        props?.isLoading(false);
      }
    });
  }

  function onUpdateCancelConfirm() {
    setConfirmData({
      ...confirmData,
      title: "Alert",
      message: ERROR_MESSAGES.reConformFormNotSaveMessage,
      closeLabel: "Cancel",
      okLabel: "Confirm",
      isShow: true,
    });
  }

  function onUpdate() {
    props?.isLoading(true);
    if (!validateForm()) {
      toast.error(ERROR_MESSAGES.checkAllFieldsMessage);
      props?.isLoading(false);
      return false;
    }

    let req = {
      execution_engine_key: executionEngineForm?.execution_engine_key,
      name: executionEngineForm?.name,
      service_name: executionEngineForm?.service_name,
      service_short_name: executionEngineForm?.service_short_name,
      task_definiton_worker: executionEngineForm?.task_definiton_worker,
      max_count: executionEngineForm?.max_count
        ? executionEngineForm?.max_count
        : 0,
      variables: executionEngineForm?.variables,
    };

    executionEngineService
      .updateExecutionEngine(executionEngineForm?.execution_engine_key, req)
      .then((resp) => {
        if (resp.status == 200 && resp?.data) {
          props?.isLoading(false);
          toast.success(SUCCESS_MESSAGES.update);
          props?.handelEdit(false);
        } else if (resp?.status == 408) {
          toast.error(ERROR_MESSAGES.timeExceedError);
          props?.isLoading(false);
        } else {
          if (resp?.status === 422) {
            toast.error(resp?.data?.detail[0].msg);
          } else {
            toast.error(
              resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
            );
          }
          props?.isLoading(false);
        }
      });
  }

  function handleAddRow() {
    const item = {
      key: "",
      value: "",
    };
    setState({ ...state, rows: [...state.rows, item] });
  }

  function handleRemoveRow(idx) {
    const newList = [
      ...state.rows.slice(0, idx),
      ...state.rows.slice(idx + 1),
    ];
    setState({ ...state, rows: newList });

    let result;
    if (newList.length > 0) {
      result = newList.reduce(function (r, e) {
        r[e.key] = e.value;
        return r;
      }, {});
    }
    setExecutionEngineForm({ ...executionEngineForm, variables: result });
  }

  function handleChangeBuildArgs(idx, fieldName, e) {
    const { key, value } = e;
    const rows = [...state.rows];

    switch (fieldName) {
      case "key":
        rows[idx] = {
          key: key,
          value: value ? value : rows[idx].value,
        };
        break;
      case "value":
        rows[idx] = {
          key: key ? key : rows[idx].key,
          value: value,
        };
        break;
      default:
        break;
    }

    let result;
    if (rows.length > 0) {
      result = rows.reduce(function (r, e) {
        r[e.key] = e.value;
        return r;
      }, {});
    }

    setState({ ...state, rows, buildArgs: result });
    setExecutionEngineForm({ ...executionEngineForm, variables: result });
  }

  function handleChangeFormSelect(event) {
    Object.keys(event).forEach(function (key) {
      setExecutionEngineForm({ ...executionEngineForm, [key]: event[key] });
    });
    error.isValid && validateForm();
  }

  function getValues(myObj) {
    let arr = [];
    arr = Object.keys(myObj).map((key, index) => (
      <tr key={index}>
        <td>{key}</td>
        <td>{myObj[key]}</td>
        <td></td>
      </tr>
    ));
    return arr;
  }

  console.log(props);

  return (
    <>
      {confirmData.isShow ? (
        <ConfirmationPopup
          data={confirmData}
          confirmCloseEvent={closeConfirm}
          confirmOkEvent={() => onUpdateCancel()}
        />
      ) : (
        ""
      )}

      <form noValidate onSubmit={(e) => e.preventDefault()}>
        <div className="row">
          <div className="col-6">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="execution_engine_key"
              handleChange={true}
              isDisabled={!(props?.isNew && !props?.isEditForm)}
              onChangeInput={handleChangeFormWithouspace}
              label="Key (Execution Engine Key)"
              isRequired={true}
              value={executionEngineForm.execution_engine_key}
            />
            {!executionEngineForm.execution_engine_key && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>

          <div className="col-6 ">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="name"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeFormWithouspace}
              label="Name"
              isRequired={true}
              value={executionEngineForm.name}
            />
            {!executionEngineForm.name && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="service_name"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeFormWithouspace}
              label="Service Name"
              isRequired={true}
              value={executionEngineForm.service_name}
            />
            {!executionEngineForm.service_name && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>

          <div className="col-6 ">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="service_short_name"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeFormWithouspace}
              label="Service Short Name"
              isRequired={true}
              value={executionEngineForm.service_short_name}
            />
            {!executionEngineForm.service_short_name && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6 ">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="task_definiton_worker"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeFormWithouspace}
              label="Task Definition Worker"
              isRequired={true}
              value={executionEngineForm.task_definiton_worker}
            />
            {!executionEngineForm.task_definiton_worker && error.isValid && (
              <label className="text-danger fs-12">{REQUIRED_ERROR}</label>
            )}
          </div>
          <div className="col-6 ">
            <Inputcomp
              type="text"
              nameClass="form-control custom-form-lh2"
              name="max_count"
              handleChange={true}
              isDisabled={!props?.isNew}
              onChangeInput={handleChangeForm}
              label="Max Count"
              isRequired={true}
              value={executionEngineForm.max_count}
            />
            {!executionEngineForm.max_count &&
              error.isValid && (
                <label className="text-danger fs-12">
                  {REQUIRED_ERROR}
                </label>
              )}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 mt-4 border-bottom">
            <div className="d-flex flex-column">
              <h4 className="mb-1 fw-500 fs-14">
                Configuration
                {/* <label className="text-danger">*</label> */}
              </h4>
              <p className="fs-12 mb-1">
                List of variables critical to execution of this execution engine.
                Configuration is typically not exposed to end users.
              </p>
              {/* {!executionEngineForm?.variables && error.isValid && (
                <label className="text-danger fs-12  mb-1">
                  {REQUIRED_ERROR}
                </label>
              )} */}
            </div>
          </div>

          <div className="table-responsive mt-2">
            <table className="table data-table data-table-vertically-center  ">
              <thead className="table-primary">
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                  <th></th>
                </tr>
              </thead>
              {props?.isNew ? (
                <tbody>
                  {state?.rows.length > 0 ? (
                    state?.rows.map((item, idx) => (
                      <tr id={"tr_" + idx} key={idx}>
                        <td>
                          <Inputcomp
                            type="text"
                            nameClass="form-control custom-form-lh2"
                            name="key"
                            handleChange={true}
                            isDisabled={false}
                            onChangeInput={(event) =>
                              handleChangeBuildArgs(idx, "key", event)
                            }
                            label=""
                            value={state.rows[idx].key}
                            id={"tr_key_" + idx}
                            isRequired={true}
                          />
                        </td>
                        <td>
                          <Inputcomp
                            type="text"
                            nameClass="form-control custom-form-lh2"
                            name="value"
                            handleChange={true}
                            isDisabled={false}
                            onChangeInput={(event) =>
                              handleChangeBuildArgs(idx, "value", event)
                            }
                            label=""
                            value={state.rows[idx].value}
                            id={"tr_value_" + idx}
                            isRequired={true}
                          />
                        </td>
                        <td>
                          <a
                            onClick={() => handleRemoveRow(idx)}
                            className="pull-right btn link text-danger"
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {ERROR_MESSAGES.noRecordsFound}
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  {/*   variables */}
                  {props?.executionEngineDetail?.variables ? (
                    getValues(props?.executionEngineDetail?.variables)
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No Configuration
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>

            {props?.isNew && (
              <a onClick={handleAddRow} className="btn pull-right link">
                + Add Row
              </a>
            )}
          </div>
        </div>
        {props?.isNew && !props?.isEditForm && (
          <div className="d-flex justify-content-end my-3">
            <Link to={"/app/execution-engines"} className="btn btn-secondary me-3">
              Cancel
            </Link>
            <button className="btn btn-primary" onClick={onSubmit}>
              Submit
            </button>
          </div>
        )}
        {props?.isNew && props?.isEditForm && (
          <div className="d-flex justify-content-between my-3">
            <AuditLog logData={props?.executionEngineDetail} />
            <div>
              <button
                className="btn btn-secondary  me-3"
                onClick={onUpdateCancelConfirm}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={onUpdate}>
                Save
              </button>
            </div>
          </div>
        )}
      </form >
    </>
  );
}

export default ExecutionEngineForm