import { Link, useLocation, navigate } from "@reach/router";
import React, { useState } from "react";
import moment from "moment";
import { Card } from "react-bootstrap";
import { productService } from "../../_services/products.service";
import { buildsService } from "../../_services/builds.service";
import { buildsApplicationService } from "../../_services/builds_application.service";

import Inputcomp from "../../widgets/Form/input/Input";
import ErrorPopup from "../../widgets/Popups/Error-Popup";
import BuildsSidebar from "./BuildsSidebar";
import { tabItems } from "./Tabhelper";
import Viewdetails from "./Viewdetails";
import BuildsForm from "./BuildsForm";
import SearchableSelect from "../../widgets/Form/Select/Searchable-Select";
import Loader from "../../widgets/loader/Loader";
import ButtonGroupComponent from "../../widgets/Button/Buttongroup/Button-Group";
import CheckboxComponent from "../../widgets/Form/Checkbox/CheckboxComponent";
import { TokenService } from "../../_services/token.service";
import {
  DATE_FORMAT,
  FETCH_LIMIT,
  ERROR_MESSAGES,
} from "../../constants/app-constants";
import { AuthService } from "../../_services/auth.service";
import Tooltip from "../../widgets/Tooltip/Tooltip";
import AuditLog from "../../widgets/AuditLog/AuditLog";
import CHOICES from "../../_helpers/CHOICES";
import { toast } from "react-toastify";

const BuildsDetails = (props) => {
  const search = useLocation().search;
  const location = useLocation();
  const accessModule = AuthService.getAllAccessList("builds");
  const appFromUrl = new URLSearchParams(search).get("app");
  const failed_builds_only_FromUrl = new URLSearchParams(search).get(
    "failed_builds_only"
  );
  const my_builds_only_FromUrl = new URLSearchParams(search).get(
    "my_builds_only"
  );
  const q_FromUrl = new URLSearchParams(search).get("q");

  const [loading, setLoading] = useState(false);

  const [loadingList, setloadingList] = useState(false);

  const [settings, setSettings] = useState({
    showFilter: true,
    isEditable: false,
    buttonIndex: 0,
  });

  const [productDropdown, setProductDropdown] = useState(null);

  const [appDropdown, setAppDropdown] = useState(null);

  const [appBuilds, setAppBuilds] = useState(null);

  const [selectedValue, setSelectedValue] = useState({
    appkey: null,
    product: "",
    application: appFromUrl,
    requestor_id: TokenService.getUserId(),
    q: q_FromUrl ? q_FromUrl : "",
    by_requestor_id:
      my_builds_only_FromUrl === null
        ? false
        : my_builds_only_FromUrl === "true"
        ? true
        : false,
    failedOnly: failed_builds_only_FromUrl
      ? failed_builds_only_FromUrl === "true"
        ? true
        : false
      : false,
    showFilter: true,
    limit: FETCH_LIMIT,
    offset: 0,
    appDetail: null,
    appkeyFlag: false,
    auth: "",
    app: "",
    pageNumber: 0,
  });
  console.log(selectedValue);
  


  const [loadMore, setLoadMore] = useState(true);

  const [error, setError] = useState({
    errorStatus: false,
    errorData: "",
    err_product: "",
    err_application: "",
    noAccessModule: false,
    noAccessModuleMessage: "",
  });

  const [errorSide, setErrorSide] = useState({
    isError: false,
    errMsg: "",
  });

  const [isLoadingSidebar, setIsLoadingSidebar] = useState(false);

  const [isRefreshBuilds, setIsRefreshBuilds] = useState(false);

  function toggleOpen() {
    setSettings({ ...settings, showFilter: !settings.showFilter });
  }

  function closeError() {
    setError({ ...error, errorStatus: false });
  }

  function getByQuery() {
    buildsApplicationService
      .getApplicationDetails(appFromUrl)
      .then((appbuildResp) => {
        if (appbuildResp.status == 200 && appbuildResp?.data) {
          onSelectChange(appbuildResp?.data?.product?.prod_key);
          setSelectedValue({
            ...selectedValue,
            product: appbuildResp?.data?.product?.prod_key,
            application: appbuildResp?.data?.app_key,
            q: q_FromUrl,
            by_requestor_id:
              my_builds_only_FromUrl === null
                ? false
                : my_builds_only_FromUrl === "true"
                ? true
                : false,
            failedOnly: failed_builds_only_FromUrl === "true" ? true : false,
            appkeyFlag: true,
            auth: appbuildResp?.data?.framework_auth_key,
            app: appFromUrl,
          });
          setError({ ...error, err_application: "", err_product: "" });
        }
      });
  }

  function searchAppBuildDetail(param, isRefresh) {
    setLoading(true);
    buildsService.getAppBuildDetail(param).then((resp) => {
      console.log(resp);
      
      if (resp.status == 200 && resp?.data) {
        if (resp?.data?.application.app_key) {
          appDropdown
            ? ""
            : onSelectChange(resp?.data?.application.product.prod_key);
        }
        if (isRefresh) {
          let itemToChange = appBuilds.find((element) => element.id == param);
          // id: "0ab1fcdc-08ea-4ea9-99c2-6bc3f2a9c583"
          itemToChange.builds_completed = resp?.data?.status;
          itemToChange.builds_successful = resp?.data?.result;

          let i = appBuilds.findIndex((item) => item.id == param);
          appBuilds[i] = itemToChange;
        }
        console.log(resp);
        
        setSelectedValue((prevSelectedValue) => ({
          ...prevSelectedValue,
          appkey: props?.appkey || '',
          appDetail: resp?.data || {},
          product: resp?.data?.application?.product?.prod_key || '',
          application: resp?.data?.application?.app_key || appFromUrl || '',
          appkeyFlag: true,
          q: q_FromUrl || '',
          by_requestor_id: my_builds_only_FromUrl === null 
            ? false 
            : my_builds_only_FromUrl === "true",
          failedOnly: failed_builds_only_FromUrl === "true",
        }));
        setError({ ...error, err_application: "", err_product: "" });
        setLoading(false);
      } else if (resp?.status == 408) {
        setError({
          ...error,
          noAccessModule: true,
          noAccessModuleMessage: ERROR_MESSAGES.timeExceedError,
        });
        setLoading(false);
      } else {
        setLoading(false);

        if (resp?.status === 422) {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.detail[0].msg,
          });
          // toast.error(resp?.data?.detail[0].msg);
        } else if (resp?.status === 404) {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.error
              ? resp?.data?.error
              : ERROR_MESSAGES.noAccessMsg,
          });
        } else {
          setError({
            ...error,
            noAccessModule: true,
            noAccessModuleMessage: resp?.data?.detail
              ? resp?.data?.detail
              : resp?.data?.error,
          });
          // toast.error(
          //   resp?.data?.detail ? resp?.data?.detail : resp?.data?.error
          // );
        }
      }
    });
  }

  function onSelectChange(event) {
    setSelectedValue({ ...selectedValue, product: event, application: "" });

    setAppDropdown("");
    if (event) {
      buildsApplicationService.getAllApplication(event).then((applications) => {
        if (applications.status == 200 && applications?.data) {
          const optionsApp = applications?.data.map((d) => ({
            value: d.app_key,
            label: d.app_key + " - " + d.name,
            enabled: d.enabled,
          }));

          let k = optionsApp.filter((x) => x.enabled);
          setAppDropdown(k);
        }
      });
    }
  }

  const handleChange = (event) => {
    setSettings({ ...settings, buttonIndex: event });
  };

  function onProductChange(event) {    
    setLoading(true);

    setAppDropdown("");

    if (event) {
      setError({ ...error, err_product: "" });
      setSelectedValue({
        ...selectedValue,
        product: event,
        application: "",
        failedOnly: false,
      });
      onSelectChange(event);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  function onApplicationChange(event) {
    if (event) {
      setError({ ...error, err_application: "" });
      setSelectedValue({ ...selectedValue, application: event });
    }
  }

  function getChanged(event) {
    setLoading(event);
  }

  React.useEffect(() => {
    getProduct();

    // $(':root').css("--containerHeight", $( .bar ).height() );
    // $(':root').css("--blockHeight", $( .foo ).height() );
  }, []);

  React.useEffect(() => {
    if (props?.appkey) {
      let isRefresh = false;
      searchAppBuildDetail(props?.appkey, isRefresh);
    } else {
      getByQuery();
    }
  }, [location]);

  function getProduct() {
    getProductList();
    setLoading(false);
  }

  function getProductList() {
    productService.getAllProducts().then((products) => {
      if (products.status == 200 && products?.data) {
        const options = products?.data.map((d) =>
          d.enabled
            ? {
                value: d.prod_key,
                label: d.prod_key + " - " + d.name,
                enabled: d.enabled,
              }
            : ""
        );
        let k = options.filter((x) => x.enabled);
        setProductDropdown(k);
      }
    });
  }

  function failedOnlyChange(event) {
    setSelectedValue({ ...selectedValue, failedOnly: !selectedValue.failedOnly });
    getValidateFilter();
  }

  function requesterIdChange(event) {
    setSelectedValue({ ...selectedValue, by_requestor_id: !selectedValue.by_requestor_id });
    getValidateFilter();
  }

  function onSubmit() {
    if (!getValidateFilter()) {
      return false;
    }

    let app = `app=${selectedValue.application}`;
    let failed_builds_only = `&failed_builds_only=${selectedValue.failedOnly}`;
    let my_builds_only = `&my_builds_only=${selectedValue.by_requestor_id}`;
    let q = selectedValue.q ? `&q=${selectedValue.q}` : "";

    if (props?.appkey) {
      navigate(
        `/app/builds/${props?.appkey}?${app}${failed_builds_only}${my_builds_only}${q}`
      );
    } else {
      navigate(
        `/app/builds/new?${app}${failed_builds_only}${my_builds_only}${q}`
      );
    }
    searchRequest();
  }

  function searchRequest() {
    if (selectedValue.application) {
      // setLoading(true);
      setIsLoadingSidebar(true);
      setError({ ...error, err_application: "", err_product: "" });

      buildsService.getAppBuilds(selectedValue).then((applist) => {
        if (applist.status == 200 && applist?.data) {
          const applicationList = applist?.data.map((d) => ({
            id: d.id,
            name: d.name,
            submitted: moment.utc(d.created_at).format(DATE_FORMAT),
            test_build: "",
            builds_completed: d.status,
            builds_successful: d.result,
            user: d.requestor.first_name + " " + d.requestor.last_name,
          }));
          setAppBuilds(applicationList);
          applicationList.length;
          // > selectedValue.limit ? setLoadMore(true) : setLoadMore(false)
          applicationList ? setIsLoadingSidebar(false) : "";
          setErrorSide({
            ...errorSide,
            isError: false,
            errMsg: "",
          });
        } else if (applist?.status == 408) {
          setErrorSide({
            ...errorSide,
            isError: true,
            errMsg: ERROR_MESSAGES.timeExceedError,
          });
          // setLoading(false);
          setIsLoadingSidebar(false);
          setAppBuilds("");
        } else {
          setErrorSide({
            ...errorSide,
            isError: true,
            errMsg: ERROR_MESSAGES.failedLoadRecordsError,
          });
          // setLoading(false)
          setIsLoadingSidebar(false);
          setAppBuilds("");
        }
      });
    }
  }

  function getValidateFilter() {
    if (!selectedValue.application && !selectedValue.product) {
      setError({
        ...error,
        err_product: "Please Select Product",
        err_application: "Please Select Application",
      });
      return false;
    } else {
      if (!selectedValue.product) {
        setError({
          ...error,
          err_product: "Please Select Product",
          err_application: "",
        });
        return false;
      } else if (!selectedValue.application) {
        setError({
          ...error,
          err_product: "",
          err_application: "Please Select Application",
        });
        return false;
      } else {
        setError({ ...error, err_application: "", err_product: "" });
        return true;
      }
    }
  }

  function handleChangeForm(event) {
    Object.keys(event).forEach(function (key) {
      setSelectedValue({ ...selectedValue, [key]: event[key] });
    });
  }

  React.useEffect(() => {
    searchRequest();
  }, [selectedValue.appkeyFlag]);

  function refreshBuildDetails() {
    if (props?.appkey) {
      setLoading(true);
      let isRefresh = true;
      searchAppBuildDetail(props?.appkey, isRefresh);
    }
  }

  // function updateOffset(e) {
  //     let pgNo = selectedValue.pageNumber+1;
  //     let offset = pgNo*selectedValue.limit;
  //     setSelectedValue({...selectedValue, offset:offset, pageNumber : pgNo})
  // }

  // function infiniteAppBuildList() {
  //   let appList = appBuilds;

  //   if (selectedValue.application) {
  //     setLoading(true);
  //     setError({ ...error, err_application: "", err_product: "" });
  //     productService.getAppBuilds(selectedValue).then(applist => {

  //       if (applist.status == 200 && applist?.data) {
  //         const applicationList = applist?.data.map(d => (
  //           {
  //             "id": d.id,
  //             "name": d.name,
  //             "submitted": moment.utc(d.created_at).format(DATE_FORMAT),
  //             "test_build": '',
  //             "builds_completed": d.status,
  //             "builds_successful": d.result,
  //             "user": d.requestor.first_name + " " + d.requestor.last_name
  //           }
  //         ))

  //         setloadingList(false)
  //         applicationList ? setLoading(false) : ''
  //       } else {
  //         setloadingList(false)
  //         setLoading(false);

  //       }
  //     })
  //   }
  // }

  function navigateToDetails(id, type) {
    let app = `app=${selectedValue.application}`;
    let failed_builds_only = `&failed_builds_only=${selectedValue.failedOnly}`;
    let my_builds_only = `&my_builds_only=${selectedValue.by_requestor_id}`;
    let q = selectedValue.q ? `&q=${selectedValue.q}` : "";
    if (type === "manage") {
      navigate(
        `/app/builds/${id}?${app}${failed_builds_only}${my_builds_only}${q}`
      );
    } else if (type === "new") {
      navigate(
        `/app/builds/new?${app}${failed_builds_only}${my_builds_only}${q}`
      );
    }
  }

  function onBack() {
    let app = `app=${selectedValue.application}`;
    let failed_builds_only = `&failed_builds_only=${selectedValue.failedOnly}`;
    let my_builds_only = `&my_builds_only=${selectedValue.by_requestor_id}`;
    let q = selectedValue.q ? `&q=${selectedValue.q}` : "";

    navigate(`/app/builds?${app}${failed_builds_only}${my_builds_only}${q}`);
  }

  function fetchMoreData(e) {
    if (e) {
      let pgNo = selectedValue.pageNumber + 1;
      let offset = pgNo * selectedValue.limit;
      setSelectedValue({ ...selectedValue, pageNumber: pgNo });
      let appReq = {
        application: selectedValue.application,
        by_requestor_id: selectedValue.by_requestor_id,
        requestor_id: selectedValue.requestor_id,
        q: selectedValue.q,
        failedOnly: selectedValue.failedOnly,
        limit: selectedValue.limit,
        offset: offset,
      };

      buildsService.getAppBuilds(appReq).then((applist) => {
        if (applist.status == 200 && applist?.data) {
          const applicationList = applist?.data.map((d) => ({
            id: d.id,
            name: d.name,
            submitted: moment.utc(d.created_at).format(DATE_FORMAT),
            test_build: "",
            builds_completed: d.status,
            builds_successful: d.result,
            user: d.requestor.first_name + " " + d.requestor.last_name,
          }));
          if (applicationList.length > 0) {
            let newlist = appBuilds.concat(applicationList);
            setLoadMore(true);
            setAppBuilds(newlist);
          } else {
            setLoadMore(false);
          }
        }
      });
    }
  }

  return (
    <div className="containWrap-inner">
      {loading ? <Loader /> : ""}
      {error.errorStatus ? (
        <ErrorPopup data={error.errorData} closePopup={closeError} />
      ) : (
        ""
      )}

      {!error.noAccessModule ? (
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Card className="bg-white custom-card list-option h-100">
                <Card.Body>
                  {(selectedValue?.product || selectedValue?.application) && (
                    <div className="h-100 d-flex flex-column">
                      <div className="">
                        <div className="d-flex align-items-center justify-content-between">
                          <Card.Title className="m-0">
                            <h4 className="m-0">App Test Builds</h4>
                          </Card.Title>

                          <div className="d-flex align-items-center justify-content-between">
                            {(settings.buttonIndex == 1 ||
                              (settings.buttonIndex == 0 &&
                                selectedValue?.appDetail)) && (
                              <button
                                disabled={!accessModule.add}
                                onClick={() =>
                                  navigateToDetails(
                                    selectedValue?.application,
                                    "new"
                                  )
                                }
                                className="btn btn-primary fs-14"
                              >
                                Submit App Build
                              </button>
                            )}
                            {!accessModule.add && (
                              <i
                                className="fa fa-info-circle ms-2"
                                aria-hidden="true"
                                data-tip
                                data-for="addTip"
                              ></i>
                            )}
                            <Tooltip
                              id="addTip"
                              place="left"
                              effect="solid"
                              msgType="operation"
                            />
                          </div>
                        </div>
                        <div className="text-end my-2">
                          <i
                            className="fa fa-filter"
                            aria-hidden="true"
                            onClick={toggleOpen}
                          ></i>
                        </div>
                      </div>
                      <div
                        className={
                          settings.showFilter
                            ? "d-block mb-3 bg-d-gray p-2"
                            : "d-none"
                        }
                      >
                        <div className="">
                          {productDropdown && (
                            <SearchableSelect
                              optionList={productDropdown}
                              dropdownName={"Product"}
                              selectedOption={selectedValue.product}
                              placeholder={"Select Product"}
                              backgroundColor={"bg-white"}
                              isEditable={productDropdown ? true : false}
                              isRequired={true}
                              onClick={onProductChange}
                            />
                          )}
                          {error.err_product && (
                            <label className="text-danger">
                              {error.err_product}
                            </label>
                          )}
                        </div>
                        <div className="mt-2">
                          <SearchableSelect
                            optionList={appDropdown}
                            dropdownName={"Application"}
                            selectedOption={selectedValue.application}
                            placeholder={"Select Application"}
                            backgroundColor={"bg-white"}
                            isEditable={appDropdown ? true : false}
                            onClick={onApplicationChange}
                            subLabel={""}
                            isRequired={true}
                          />
                          {error.err_application && (
                            <label className="text-danger">
                              {error.err_application}
                            </label>
                          )}
                        </div>
                        <div className="mt-2">
                          <Inputcomp
                            type="text"
                            nameClass="form-control custom-form-lh2"
                            name="q"
                            handleChange={true}
                            isDisabled={false}
                            onChangeInput={handleChangeForm}
                            label="Build Name/ Build ID"
                            isRequired={false}
                            placeholder={"Build name/Exact (full) Build ID"}
                            value={selectedValue.q}
                            onKeyPress={onSubmit}
                          />
                        </div>
                        <div className="mt-2">
                          <CheckboxComponent
                            label={"Failed Build Only"}
                            type={"checkbox"}
                            id={"checkbox_01"}
                            nameField={"failed_only"}
                            defaultChecked={selectedValue.failedOnly}
                            onClick={failedOnlyChange}
                          />
                        </div>
                        <div className="mt-2">
                          <CheckboxComponent
                            label={"My Builds"}
                            type={"checkbox"}
                            id={"checkbox_02"}
                            nameField={"by_requestor_id"}
                            defaultChecked={selectedValue.by_requestor_id}
                            onClick={requesterIdChange}
                          />
                        </div>
                        <div className="mt-2 left-btn">
                          <input
                            className="btn btn-primary"
                            type="submit"
                            value="Search"
                            onClick={onSubmit}
                          />
                        </div>
                      </div>
                      {isLoadingSidebar ? (
                        <div className="text-center"> Please Wait....</div>
                      ) : errorSide.isError ? (
                        <div className="text-center text-danger">
                          {errorSide.errMsg}
                        </div>
                      ) : (
                        <BuildsSidebar
                          sidedata={appBuilds}
                          loadingList={loadingList}
                          appkey={props?.appkey}
                          reqData={selectedValue}
                          getMoreData={fetchMoreData}
                          loadmore={loadMore}
                        />
                      )}
                      {/* <BuildsSidebar
                        sidedata={appBuilds}
                        loadingList={loadingList}
                        appkey={props?.appkey}
                        reqData={selectedValue}
                        getMoreData={fetchMoreData}
                        loadmore={loadMore}
                      /> */}

                      {/*  */}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
            <div className="col-8 ps-0">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <Card className="bg-white custom-card custom-card-full">
                    <Card.Body>
                      {selectedValue?.appDetail ? (
                        <Card.Title>
                          <div className="d-flex align-items-start justify-content-between mb-3">
                            <div>
                              <h4 className="">
                                {selectedValue?.appDetail?.name}
                              </h4>
                              {selectedValue?.appDetail && (
                                <p className="m-0">
                                  <label className="fs-14 mb-1">
                                    {
                                      selectedValue?.appDetail?.application
                                        ?.product?.name
                                    }{" "}
                                    (
                                    {
                                      selectedValue?.appDetail?.application
                                        ?.product?.prod_key
                                    }
                                    )
                                  </label>
                                  <br />
                                  <label className="fs-14">
                                    {
                                      selectedValue?.appDetail?.application
                                        ?.name
                                    }{" "}
                                    (
                                    {
                                      selectedValue?.appDetail?.application
                                        ?.app_key
                                    }
                                    )
                                  </label>
                                </p>
                              )}
                            </div>
                            <div className="d-flex align-items-start">
                              {/* <button
                                  className="btn btn-primary me-2"
                                  onClick={refreshBuildDetails}
                                >
                                  Refresh
                                </button> */}
                              {settings.buttonIndex == 0 ? (
                                selectedValue?.appDetail?.status ==
                                  CHOICES?.BuildStatus?.SUCCEEDED ||
                                selectedValue?.appDetail?.status ==
                                  CHOICES?.BuildStatus?.ERROR ? null : (
                                  <button
                                    className="btn btn-primary me-2"
                                    onClick={refreshBuildDetails}
                                  >
                                    Refresh
                                  </button>
                                )
                              ) : selectedValue?.appDetail?.status ==
                                  CHOICES?.BuildStatus?.SUCCEEDED ||
                                selectedValue?.appDetail?.status ==
                                  CHOICES?.BuildStatus?.ERROR ? null : (
                                <button
                                  className="btn btn-primary me-2"
                                  onClick={() => {
                                    setIsRefreshBuilds(!isRefreshBuilds);
                                    refreshBuildDetails();
                                  }}
                                >
                                  Refresh
                                </button>
                              )}
                              <a
                                className="btn btn-white no-link"
                                onClick={onBack}
                              >
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </Card.Title>
                      ) : (
                        <Card.Title>
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <h4 className="">Submit App Test Build</h4>
                            <div>
                              <a
                                className="btn btn-white no-link"
                                onClick={onBack}
                              >
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </Card.Title>
                      )}

                      {(settings.buttonIndex == 0 ||
                        settings.buttonIndex == 1) &&
                      (selectedValue?.appDetail || props?.appkey) ? (
                        <div className="my-3">
                          <ButtonGroupComponent
                            valueSelected={settings.buttonIndex}
                            options={tabItems}
                            onClick={handleChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-12">
                        {!selectedValue?.appDetail &&
                          selectedValue?.product &&
                          selectedValue?.application && (
                            <div className="row mt-4">
                              <div className="col-12 col-md-6">
                                <div className="d-flex flex-column">
                                  <label className="mb-1 fw-500 fs-14">
                                    Product
                                  </label>
                                  <label className="fs-14">
                                    {selectedValue?.product}
                                  </label>
                                </div>
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="d-flex flex-column">
                                  <label className="mb-1 fw-500 fs-14">
                                    Application
                                  </label>
                                  <label className="fs-14">
                                    {selectedValue?.application}
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        {settings.buttonIndex == 0 &&
                        selectedValue?.appDetail ? (
                          <BuildsForm appDetail={selectedValue?.appDetail} />
                        ) : (
                          ""
                        )}
                        {settings.buttonIndex == 0 &&
                        !selectedValue?.appDetail &&
                        selectedValue.app ? (
                          <BuildsForm
                            appDetail={""}
                            app={selectedValue.app}
                            auth={selectedValue.auth}
                            onChange={getChanged}
                          />
                        ) : (
                          ""
                        )}
                        {settings.buttonIndex == 1 && props?.appkey ? (
                          <Viewdetails
                            appkey={props?.appkey}
                            onChange={getChanged}
                            isRefreshBuilds={isRefreshBuilds}
                          />
                        ) : (
                          ""
                        )}
                        <div className="my-3">
                          <AuditLog logData={selectedValue?.appDetail} />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row err-msg-container">
          <div className="col-12">
            <Card className="bg-white custom-card list-option">
              <Card.Body>
                <div className="row err-msg">
                  <label className="text-center">
                    {error.noAccessModuleMessage
                      ? error.noAccessModuleMessage
                      : ERROR_MESSAGES.noAccessMsg}
                  </label>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuildsDetails;
